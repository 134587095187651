import { createAction, props } from "@ngrx/store";

// export const getEventTemplateList = createAction('[Auth] get Event Template',
//     props<{ payload: any }>()
// )

export const getAppoinmentCategoryList = createAction('[Auth] get Appoinment Categories',
    props<{ payload: any }>()
)

export const getAppoinmentCategoryListSuccess = createAction('[Auth] get Appoinment Categories Success',
    props<{ appoinmentCategoryListData: any }>()
)

export const errorMessage = createAction('[Auth] error Message',
    props<{ errorData: any }>()
)
export const clearErrorMessage = createAction('[Auth] clear Error Message',
    props<{ payload: string }>()
)
export const clearErrorMessageSuccess = createAction('[Auth] clear Error Message Success')